* {
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
  background: #faf9f8;
}

#root {
  height: 100%;
}

.error-field > .MuiInputBase-root {
  border: 1px solid #c4165e !important;
}

.inputAuthCode {
  width: 40px;
  height: 40px;
  border: 1px solid #8c8a97;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #8c8a97;
  text-align: center;
}
.containerAuthCode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  margin-bottom: 20px;
}
